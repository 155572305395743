/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;900&display=swap");

body,
html {
  margin: 0;
  font-family: "Poppins", "Lato", sans-serif;
  // font-size: 18px;
  font-size: 1.125rem; /* 18px / 16px (browser default) = 1.125rem */
  //background-color: #EDF1F5;
  scroll-behavior: smooth;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

/*
 Temporary Styling for auth screens.
 No framework is added, you can add framework of your choice.
 */
.login-page,
.register-page {
  margin: 0;
  padding: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  height: 100vh;
  //background-color: #f2f2f2;
  background: linear-gradient(114.16deg, rgba(0, 85, 134, 0.8) 33.71%, rgba(255, 89, 1, 0.8) 106.41%),
    url(/assets/images/login-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .card {
    width: 100%;
    margin-top: 60px;
    //height: 400px;
    // border-radius: 10px;
    // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    // background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 30px;
    // padding-bottom: 30px;

    .card-header {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      width: 100%;

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        .form-group {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;

          small {
            display: block;
          }
        }

        label {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
          display: block;
        }

        input[type="text"],
        input[type="email"],
        input[type="password"] {
          //width: 100%;
          //height: 40px;
          border: 1px solid #ccc;
          border-radius: 17px;
          padding: 10px;
          font-size: 16px;
          outline: none;
        }

        .form-submit {
          width: 100%;
          margin-top: 20px;
        }

        // button {
        //   width: 100%;
        //   height: 40px;
        //   background-color: #007bff;
        //   color: white;
        //   border: none;
        //   border-radius: 5px;
        //   font-size: 16px;
        //   cursor: pointer;

        //   &:hover {
        //     background-color: #0069d9;
        //   }
        // }

        .btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 0.875rem;
          font-weight: 500;
          padding: 0.3125rem 0.75rem;
          border-radius: 0.75rem;
          transition: all 0.25s ease-in-out;
          height: 40px;
          width: 130px;

          &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }

        .btn-primary {
          background-color: #005586;
          color: #ffffff;

          &:hover {
            background-color: #005586;
            color: #ffffff;
          }
        }
        .btn-secondary {
          background-color: #c14e55;
          color: #ffffff;

          &:hover {
            background-color: #c14e55;
            color: #ffffff;
          }
        }

        .invalid-feedback {
          color: red;
          //font-size: 12px;
          margin-top: 5px;
        }
      }
    }
  }
}
.ngx-pagination .current {
  background: #1068fa !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-md border border-gray-300 px-3 py-3 text-xs placeholder-gray-400 focus:z-10  focus:border-primary-500 focus:outline-none focus:ring-primary-500 dark:border-night-500 dark:bg-night-700 dark:text-night-400 dark:placeholder-night-200;
  }

  [type="checkbox"],
  [type="radio"] {
    @apply h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-night-600 dark:bg-night-700 dark:ring-offset-night-800 dark:focus:ring-blue-600;
  }
}

@layer components {
  .dropdown-content {
    @apply pointer-events-none scale-95 opacity-0 duration-100 ease-in;
  }

  .dropdown:hover > .dropdown-content {
    @apply pointer-events-auto block scale-100 animate-fade-in-up opacity-100 duration-200;
  }
}

/** Scroll bar **/
@supports selector(::-webkit-scrollbar) {
  .supports-scrollbars\: pr-2 {
    padding-right: 0.5rem;
  }
}

/** Plugins **/
@import "./assets/styles/apexchart.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/** Charts **/
.bg-chart {
  background: linear-gradient(152.97deg, #005586 18.75%, #000000 100%);
}

.bg-approved {
  background: linear-gradient(180deg, #3cb539 0%, #4a4a4a 100%);
}

.bg-failed {
  background: linear-gradient(180deg, #c14e55 0%, #4a4a4a 100%);
}

.bg-blupay {
  background: linear-gradient(180deg, #005586 0%, #4a4a4a 100%);
}

.bg-partners {
  background: linear-gradient(180deg, #cebd24 0%, #4a4a4a 100%);
}

.text-danger {
  color: #dc3545 !important;
}
